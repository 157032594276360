<template>
  <div class="customer">
    <Loader v-if="isLoading" />

    <div class="container" v-if="!isLoading">
      <div class="h4 customer-name">{{ customerPage.name }}</div>
      <p class="customer-description">{{ customerPage.description }}</p>
      <div class="customer-content marked-text" v-html="marked(customerPage.content)"></div>
      <video
        v-if="customerPage.video"
        :src="getAssetUrl(customerPage.video.url, true)"
        controls
        disablePictureInPicture
        controlslist="nodownload noplaybackrate" />
    </div>
  </div>
</template>

<script>
import '@/assets/sass/customer.sass';
import { getAssetUrl } from '@/helpers';
import config from '@/config';
import { mapState } from 'vuex';
import marked from 'marked';
import Loader from '@/components/Loader.vue';

export default {
  name: 'Customer',
  components: { Loader },
  data() {
    return {
      marked,
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      customerPage: (state) => state.customer.customerPage,
    }),
    API_URL() {
      return config.API_URL;
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('getCustomerPage').then(() => {
        document.querySelectorAll('.customer-content img').forEach((img) => {
          img.src = this.API_URL + img.attributes[0].value;
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    getAssetUrl(data) {
      return getAssetUrl(data);
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData();
    this.$emit('setLastCrumb', 'Заказчикам');
  },
};
</script>
